footer {
  background-color: transparent !important;
  // position: fixed;
  // bottom: -70px;
  padding: 10px 0 0 10px !important;

  @include mobile {
    position: relative;
    bottom: 0;
  }
  h3 {
    font-family: 'Eina03-Bold';
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 30px;
    color: #5b5b5b;
  }
}
