.max-width-960 {
  max-width: 960px;
  margin: 0 auto;
}
iframe {
  width: 100%;
  height: 2300px;
  transition: height 0.3s linear;
  margin-top: 440px;
  @include mobile {
    margin-top: 40px;
  }
}
.order-1 {
  order: 1;
}
.order-2 {
  order: 2;
}
.order-3 {
  order: 3;
}
li {
  cursor: pointer;
  font-family: 'Eina03-Regular';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  text-transform: capitalize;
  margin-bottom: 25px;
}
.scroll-to-top {
  position: absolute;
  right: -25px;
  bottom: 0;
}
