@font-face {
  font-family: "Eina03-Bold";
  src: url("../fonts/eina03-bold/Eina03-Bold.eot") format("embedded-opentype"),
    url("../fonts/eina03-bold/Eina03-Bold.woff") format("woff"),
    url("../fonts/eina03-bold/Eina03-Bold.ttf") format("truetype"),
    url("../fonts/eina03-bold/Eina03-Bold.svg#Eina03-Bold") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Eina03-Light";
  src: url("../fonts/eina03-light/Eina03-Light.eot") format("embedded-opentype"),
    url("../fonts/eina03-light/Eina03-Light.woff") format("woff"),
    url("../fonts/eina03-light/Eina03-Light.ttf") format("truetype"),
    url("../fonts/eina03-light/Eina03-Light.svg#Eina03-Light") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Eina03-Regular";
  src: url("../fonts/eina03-regular/Eina03-Regular.eot")
      format("embedded-opentype"),
    url("../fonts/eina03-regular/Eina03-Regular.woff") format("woff"),
    url("../fonts/eina03-regular/Eina03-Regular.ttf") format("truetype"),
    url("../fonts/eina03-regular/Eina03-Regular.svg#Eina03-Regular")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Eina03-SemiBold";
  src: url("../fonts/eina03-semibold/Eina03-SemiBold.eot")
      format("embedded-opentype"),
    url("../fonts/eina03-semibold/Eina03-SemiBold.woff") format("woff"),
    url("../fonts/eina03-semibold/Eina03-SemiBold.ttf") format("truetype"),
    url("../fonts/eina03-semibold/Eina03-SemiBold.svg#Eina03-SemiBold")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Eina02-SemiBold";
  src: url("../fonts/eina02-semiBold/Eina02-SemiBold.eot")
      format("embedded-opentype"),
    url("../fonts/eina02-semiBold/Eina02-SemiBold.woff") format("woff"),
    url("../fonts/eina02-semiBold/Eina02-SemiBold.ttf") format("truetype"),
    url("../fonts/eina02-semiBold/Eina02-SemiBold.svg#Eina02-SemiBold")
      format("svg");
  font-weight: normal;
  font-style: normal;
}
